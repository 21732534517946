import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import SupportFiles from "pages/forms/customForms/foreignTransfer/SupportFiles";
import { bool, func, string } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as transferSupportSelectors } from "reducers/supportTransferReceived";
import * as config from "util/config";
import { get } from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "support.transfer.foreign.received";
const DEFAULT_MAX_LENGTH = 35;
const ID_ACTIVITY = `${FORM_ID}.send`;

const SupportTransferReceivedModal = ({
    handleCloseModal,
    isDesktop,
    isFetching,
    showModal,
    currentLang,
    onSubmit,
}) => {
    const relationsConfig = config.get("support.transfer.foreign.relation", "Amigo|Cliente").split("|");
    const relationList = [];
    relationsConfig.forEach((el) => {
        relationList.push({ id: el, label: el });
    });

    const genericProps = {
        fromBackoffice: false,
        idActivity: ID_ACTIVITY,
        idTransactionTicket: null,
        isRequired: true,
        lang: currentLang,
        mode: "edit",
    };

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={() => {
                handleCloseModal();
            }}
            show={showModal}
            className={classNames({ drawer: true })}>
            <div className="modal-container">
                <Modal.Header closeButton className="mb-7" />
                <Modal.Body>
                    <Box
                        display="flex"
                        column
                        alignX="between"
                        alignY="between"
                        className="scrollable-content px-0 px-md-9"
                        fullWidth
                        fullHeight>
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={() => {
                                const requiredMessage = get("global.validation.error.required");
                                return Yup.object().shape({
                                    reason: Yup.string().required(requiredMessage),
                                    relation: Yup.array().required(requiredMessage),
                                    supportFiles: Yup.array().required(requiredMessage),
                                });
                            }}
                            onSubmit={(values) => onSubmit(values)}>
                            {(propsForm) => (
                                <Form className="full-width full-height min-height-0">
                                    <Box display="flex" column fullWidth fullHeight className="px-0 px-md-0">
                                        <Box display="flex" column alignX="between" className="mb-auto" fullHeight>
                                            <Box display="flex" column className="mb-3 mb-md-7 mt-3">
                                                <Text
                                                    key="text"
                                                    align="center"
                                                    labelKey={`forms.${FORM_ID}.drawer.info`}
                                                />
                                            </Box>

                                            <Box display="flex" column>
                                                <Box display="flex" column className="mb-4 mb-md-8">
                                                    <Field
                                                        {...genericProps}
                                                        idForm={`forms.${FORM_ID}`}
                                                        component={TextField}
                                                        name="reason"
                                                        idField="reason"
                                                        placeholder={get(`forms.${FORM_ID}.reason.placeholder`)}
                                                        labelNoMarginTop
                                                        className="mb-5"
                                                        maxLength={DEFAULT_MAX_LENGTH}
                                                    />
                                                </Box>

                                                <Box display="flex" column className="mb-3 mb-md-2">
                                                    <Field
                                                        {...genericProps}
                                                        idForm={FORM_ID}
                                                        component={FormFieldsComponents.Selector}
                                                        key="relation"
                                                        name="relation"
                                                        idField="relation"
                                                        labelIdField="react-select-beneficiaryAccount-input"
                                                        optionList={relationList}
                                                        customPlaceholder={get("forms.placeholder.select")}
                                                        renderAs="combo"
                                                        isRequired
                                                        labelNoMarginTop
                                                        onChange={() => {}}
                                                    />
                                                </Box>
                                            </Box>

                                            <Box display="flex" column className="mb-3 mb-md-7">
                                                <Text
                                                    key="text"
                                                    align="center"
                                                    labelKey={`forms.${FORM_ID}.drawer.support.info`}
                                                />
                                            </Box>

                                            <Box display="flex" alignX="left">
                                                <Field
                                                    {...genericProps}
                                                    component={SupportFiles}
                                                    name="supportFiles"
                                                    idField="supportFiles"
                                                    ID_FORM={"transfers.foreign"}
                                                    isDesktop={isDesktop}
                                                    supportDocuments={propsForm?.values?.supportFiles || []}
                                                    error={propsForm?.errors?.supportFiles}
                                                />
                                            </Box>

                                            <Box display="flex" className="pb-9 pb-md-12" column>
                                                <Button
                                                    block
                                                    type="submit"
                                                    label="global.confirm"
                                                    bsStyle="primary"
                                                    loading={isFetching}
                                                    disabled={false}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Modal.Body>
            </div>
        </Modal>
    );
};

SupportTransferReceivedModal.propTypes = {
    handleCloseModal: func,
    isDesktop: bool,
    isFetching: bool,
    showModal: bool.isRequired,
    currentLang: string.isRequired,
    onSubmit: func.isRequired,
};

SupportTransferReceivedModal.defaultProps = {
    handleCloseModal: () => {},
    isDesktop: false,
    isFetching: false,
};

const mapStateToProps = (state) => ({
    isFetching: transferSupportSelectors.isFetchingSend(state),
    currentLang: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(resizableRoute(SupportTransferReceivedModal));
