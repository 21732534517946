import { Buffer } from "buffer";
import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { SCHEDULER_DATE } from "pages/forms/_components/_fields/ReadText";
import RadioButtonCustom from "pages/forms/customForms/_customFields/RadioButtonCustom";
import ShowInfoFile from "pages/forms/customForms/_customFields/ShowInfoFile";
import { bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { routerActions } from "react-router-redux/actions";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import { validationText, DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import * as Yup from "yup";
import { numberFormat } from "util/number";
import { isFromNotificationRedirection } from "util/general";
import * as schedulerUtils from "util/scheduler";
import { actions as notificationActions } from "reducers/notification";
import FormTransition from "../_components/FormTransition";

let ID_FORM = "role.payment";
let ID_ACTIVITY = `${ID_FORM}.send`;
let ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const RolePayment = (props) => {
    const {
        dispatch,
        mode,
        // fromTransaction,
        location,
        transaction,
        // fileData,
        match,
        manualPaymentList,
        fromManualRolePayment,
        preDataForm,
        isSuppliersPayment,
    } = props;
    const { creationDateTime } = transaction;

    const MANUAL_TYPE = "inputManually";
    const FILE_TYPE = "file";

    const optionUploadPayment = [
        { key: FILE_TYPE, label: "forms.role.payment.fileType.label", value: FILE_TYPE },
        { key: MANUAL_TYPE, label: "forms.role.payment.manualType.label", value: MANUAL_TYPE },
    ];

    const [uploadType, setUploadType] = useState(FILE_TYPE);
    const [validLines, setValidLines] = useState(0);
    const [invalidLines, setInvalidLines] = useState(0);
    const [templateLoaded, setTemplateLoaded] = useState(false);
    const [infoErrorFile, setInfoErrorFile] = useState({});
    const [title, setTitle] = useState("");
    const [loadCompleted, setLoadCompleted] = useState(false);

    useEffect(() => {
        if (isSuppliersPayment) {
            ID_FORM = "suppliers.payment";
            ID_ACTIVITY = `${ID_FORM}.send`;
            ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
        } else if (transaction?.idActivity) {
            ID_ACTIVITY = transaction.idActivity;
            ID_FORM = ID_ACTIVITY.substring(0, ID_ACTIVITY.length - 5);
            ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
        } else {
            ID_FORM = "role.payment";
            ID_ACTIVITY = `${ID_FORM}.send`;
            ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
        }
        setTitle(`forms.${ID_FORM}.formName`);

        if (mode === "edit" && !preDataForm) {
            const formData = { debitAccount: null };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
    }, [dispatch, mode, location]);

    useEffect(() => {
        if (!preDataForm) {
            return;
        }

        const { hasPendingPaymentProcess, allowPayroll } = preDataForm;
        if (hasPendingPaymentProcess === "true" || hasPendingPaymentProcess === true || !allowPayroll) {
            const msgKey = !allowPayroll ? "role.payment.not.allowed" : "role.payment.validation.pending.process.error";
            setTimeout(
                () => dispatch(notificationActions.showNotification(i18nUtils.get(msgKey), "error", ["desktop"])),
                0,
            );

            dispatch(routerActions.replace({ pathname: "/desktop", state: { transition: "transition-flow-close" } }));
        }
    }, [preDataForm]);

    const beforeTemplateLoad = () => {
        setTemplateLoaded(true);
    };

    const validationSchemaFile = Yup.object().shape({
        uploadBy: validationText(),
        file: validationText(`forms.role.payment.message.addFile`),
        debitAccount: Yup.string().required(i18nUtils.get("forms.role.payment.debitAccount.error.required")),
        commentary: Yup.string().required(i18nUtils.get(DEFAULT_REQUIRED)),
    });
    const validationSchemaManually = Yup.object().shape({
        uploadBy: validationText(),
        inputManually: validationText(`forms.role.payment.message.addItemRole`).nullable(),
        debitAccount: Yup.string().required(i18nUtils.get("forms.role.payment.debitAccount.error.required")),
        commentary: Yup.string().required(i18nUtils.get(DEFAULT_REQUIRED)),
    });

    const [validationSchema, setValidationSchema] = useState(validationSchemaFile);

    const mapValidationField = new Map();
    mapValidationField.set(FILE_TYPE, validationSchemaFile);
    mapValidationField.set(MANUAL_TYPE, validationSchemaManually);
    const { decimalSeparator, thousandSeparator } = numberFormat();

    /**
     * Util Functions
     */

    const updateAmountField = (amountTotal, currency, setFieldValue) => {
        if (setFieldValue && currency) {
            setFieldValue("amount", { currency, quantity: amountTotal });
        }
    };

    const selectUploadType = (setFieldValue, uploadTypeCustom, values, setErrors) => {
        setUploadType(uploadTypeCustom);
        setValidationSchema(mapValidationField.get(uploadTypeCustom));
        setErrors({});
        setValidLines(0);
        setFieldValue("validLines", 0);
        setInvalidLines(0);
        setInfoErrorFile({});

        if (templateLoaded) {
            setTemplateLoaded(false);
        }

        const { uploadBy } = values;
        if (
            uploadTypeCustom === MANUAL_TYPE &&
            uploadBy &&
            uploadTypeCustom !== uploadBy &&
            !fromManualRolePayment &&
            !templateLoaded
        ) {
            setFieldValue("inputManually", []);
            setFieldValue("file", []);
            setFieldValue("amount", {});
        }
        if (uploadTypeCustom === FILE_TYPE && uploadBy !== FILE_TYPE) {
            setFieldValue("inputManually", []);
            setFieldValue("file", []);
            setFieldValue("amount", {});
            if (fromManualRolePayment) {
                dispatch(formActions.rolePaymentSetDetailData([], {}, false, true, "", "", null));
            }
        }
    };

    const getLineFields = (line) => {
        let amount = line.substring(59, 71).trim();
        const position = amount.length - 2;
        amount = [amount.slice(0, position), ".", amount.slice(position)].join("");
        amount = parseFloat(amount);

        const bankId = parseInt(line.substring(50, 59), 10);
        const bank = props.preDataForm.bankList.find((b) => parseInt(b.id, 10) === bankId);

        const fields = {
            registerType: line.substring(0, 1),
            bankType: line.substring(1, 2),
            accountNumber: parseInt(line.substring(2, 19).trim(), 10),
            beneficiaryName: line.substring(19, 49).trim(),
            productType: line.substring(49, 50),
            bankId,
            bank,
            amount: { currency: "USD", quantity: amount },
            email: line.length > 71 ? line.substring(71).trim() : null,
        };

        return fields;
    };
    const processFileToObject = (fileString) => {
        const dataDecodedBuffer = Buffer.from(fileString, "base64");
        if (!dataDecodedBuffer) {
            return {};
        }
        const dataDecoded = dataDecodedBuffer.toString("utf-8");
        const splitLine = dataDecoded.split(/\r?\n/);
        if (!splitLine) {
            return {};
        }

        const header = splitLine[0];
        const result = {
            idFileProcess: header,
        };
        const itemArray = splitLine.slice(1, splitLine.length - 1).map((item) => getLineFields(item));

        result.items = itemArray;

        return result;
    };

    const processFileUpload = (setFieldValue, data, errors, setErrors) => {
        const { invalidLines: errorLines, validLines: correctLines, totalAmount } = data;

        const { file, ...others } = errors;
        setErrors({
            ...others,
        });

        if (!data || !data.fileContent) {
            return;
        }
        const resultProcessFile = processFileToObject(data.fileContent);
        setFieldValue("inputManually", resultProcessFile.items);
        setFieldValue("amount", totalAmount);
        setValidLines(correctLines);
        setFieldValue("validLines", correctLines);
        setInvalidLines(errorLines);
        setInfoErrorFile(data);
    };

    const showNotification = () => {
        if (loadCompleted) {
            const notificationType = invalidLines > 0 ? "error" : "success";
            dispatch(notificationActions.showNotification(infoErrorFile.message, notificationType, ["form"]));
        }
    };

    const handleBack = () => {
        if (isFromNotificationRedirection(location)) {
            dispatch(routerActions.push("/desktop"));
        } else {
            dispatch(routerActions.goBack());
        }
    };

    const getOperationTypeText = () => {
        let formId = ID_FORM;
        if (transaction?.idActivity) {
            const splittedIdActivity = transaction.idActivity.split(".send");
            if (splittedIdActivity.length > 0) {
                formId = splittedIdActivity[0];
            }
        }
        return `${
            transaction?.programed ? i18nUtils.get("forms.transaction.ticket.operationType.program") : ""
        }${i18nUtils.get(`forms.${formId}.label`)}`;
    };

    /**
     * Render Pages
     */

    const templateEnabled = (boolean) => boolean;
    const renderFields = (setFieldValue, values, setValues, renderScheduler, errors, setErrors) => {
        const { currentLang, fromBackoffice } = props;
        const idTransaction = transaction?.idTransaction;
        const totalLines = transaction?.data?.totalPaymentsCount || values?.validLines || values?.inputManually?.length;
        const preData = preDataForm || {
            debitAccountList: [],
            currencyList: [],
            bankList: [],
        };
        templateEnabled(values?.uploadBy === "inputManually");

        const accountOptions = {
            options: preData.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
                disabled: acc.isDisabled,
            })),
        };

        const optionCurrencyList = preData.currencyList?.map((item) => ({
            currency: item.backendId,
            label: item.code,
        }));

        // NO BORRAR (IMPLEMENTACION PRODUCTO)
        // const fileListMap = {
        //     en: {
        //         idFile: 2,
        //         fileName: "sampleFile.txt",
        //         contents: null,
        //         idForm: "salaryPayment",
        //         formVersion: 1,
        //         idField: "sampleFile",
        //         lang: "en",
        //     },
        //     es: {
        //         idFile: 1,
        //         fileName: "archivoEjemplo.txt",
        //         contents: null,
        //         idForm: "salaryPayment",
        //         formVersion: 1,
        //         idField: "sampleFile",
        //         lang: "es",
        //     },
        // };

        const optionBankList = preData.bankList || [];

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
            dispatch,
        };

        const fileLoaded = values?.file?.length > 0 && loadCompleted;

        const getDataAmount = (amount) => ({
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: [
                {
                    id: amount?.currency,
                    label: amount?.currency ? i18nUtils.get(`currency.label.${amount?.currency}`) : "",
                },
            ],
        });

        let totalAmount = null;
        if (fromManualRolePayment && !templateLoaded) {
            totalAmount = {
                currency: "USD",
                quantity: manualPaymentList.reduce((acc, val) => acc + parseFloat(val.amount.quantity), 0),
            };
        }

        return (
            <>
                {mode === "edit" && (
                    <Row gapY="5">
                        <Col xs={6}>
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="default"
                                className="pt-8 pb-10 px-9">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.ProductselectorCustom}
                                    data={accountOptions}
                                    key="debitAccount"
                                    name="debitAccount"
                                    renderAs="combo"
                                    idField="debitAccount"
                                    value={props.data?.debitAccountId}
                                    labelNoMarginTop
                                    autoFocus
                                />
                            </Box>
                        </Col>

                        <Col xs={6}>
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="default"
                                className="pt-8 pb-10 px-9">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Text}
                                    key="commentary"
                                    name="commentary"
                                    idField="commentary"
                                    value={values.commentary}
                                    maxLength={50}
                                    validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                                    labelNoMarginTop
                                />
                            </Box>
                        </Col>
                        <Col xs={6}>
                            <Box
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="default"
                                className="py-7 px-9"
                                fullHeight>
                                <Field
                                    {...genericProps}
                                    component={RadioButtonCustom}
                                    options={optionUploadPayment}
                                    key="uploadBy"
                                    name="uploadBy"
                                    idField="uploadBy"
                                    isRequired
                                    onChange={(uploadOption) => {
                                        selectUploadType(setFieldValue, uploadOption, values, setErrors);
                                    }}
                                    value={
                                        fromManualRolePayment
                                            ? optionUploadPayment[1].value
                                            : values.uploadBy || optionUploadPayment[0].value
                                    }
                                    labelNoMarginTop
                                    alignX="flex-start"
                                    radioButtonClassName="mx-0"
                                    gap="7"
                                />
                            </Box>
                        </Col>
                        {mode === "edit" && <Col xs={6}>{renderScheduler(false)}</Col>}

                        {/**
                         * UPLOAD FILE TYPE
                         */}

                        <Col xs={12}>
                            <Box
                                position="relative"
                                display="flex"
                                column
                                background="component-background"
                                borderRadius="default"
                                className={classNames("pt-8 pb-10 px-9", { "pb-8": !fileLoaded })}
                                gap="12">
                                {uploadType === FILE_TYPE && mode === "edit" && (
                                    <Box display="flex" column position="relative">
                                        <Text
                                            labelKey="forms.role.payment.fileProcess.label"
                                            color="primary"
                                            size="4"
                                            bold
                                        />
                                        <Text
                                            labelKey="forms.role.payment.file.label"
                                            color="heading-color"
                                            size="6"
                                            className="mb-7"
                                        />
                                        {/* NO BORRAR (IMPLEMENTACION PRODUCTO) */}
                                        {/* <Box position="absolute" right="9">
                                            <Field
                                                {...genericProps}
                                                component={FormFieldsComponents.Inputfile}
                                                key="file"
                                                name="file"
                                                idField="file"
                                                noLabel
                                                idActivity={ID_ACTIVITY}
                                                acceptedFileTypes={["text/plain"]}
                                                maxFileSizeMB={4}
                                                allowMultiple={false}
                                                allowImagePreview
                                                maxTotalFileSize={4}
                                                maxFiles={1}
                                                handleFileProcess={(data) => processFileUpload(setFieldValue, data)}
                                                onRemoveFile={() => {
                                                    setInvalidLines(0);
                                                    setValidLines(0);
                                                    setFieldValue("inputManually", []);
                                                    setFieldValue("amount", { currency: "USD", quantity: 0 });
                                                }}
                                                handleInvalidLines={({ error, file }) => {
                                                    setFieldValue("file", [file]);
                                                    error("oh no");
                                                }}
                                            />
                                        </Box> */}

                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Inputfile}
                                            key="file"
                                            name="file"
                                            idField="file"
                                            noLabel
                                            // value={values.file}
                                            idActivity={ID_ACTIVITY}
                                            acceptedFileTypes={["text/plain"]}
                                            maxFileSizeMB={4}
                                            allowMultiple={false}
                                            allowImagePreview
                                            maxTotalFileSize={4}
                                            maxFiles={1}
                                            handleFileProcess={(data) => {
                                                setLoadCompleted(true);
                                                processFileUpload(setFieldValue, data, errors, setErrors);
                                            }}
                                            showNotification={() => {
                                                showNotification();
                                            }}
                                            onRemoveFile={() => {
                                                setLoadCompleted(false);
                                                setInvalidLines(0);
                                                setValidLines(0);
                                                setFieldValue("validLines", 0);
                                                setFieldValue("inputManually", []);
                                                setFieldValue("amount", { currency: "USD", quantity: 0 });
                                                setFieldValue("file", []);
                                            }}
                                            handleInvalidLines={({ error, file }) => {
                                                setFieldValue("file", [file]);
                                                dispatch(
                                                    notificationActions.showNotification(
                                                        infoErrorFile.message,
                                                        "error",
                                                        ["form"],
                                                    ),
                                                );
                                                error("oh no");
                                            }}
                                        />
                                        {invalidLines > 0 && (
                                            <Box display="flex" fullWidth flex1 position="absolute" top="100">
                                                <Disclaimer
                                                    className="flex pt-md-6"
                                                    styled="error"
                                                    background="none"
                                                    notBorder
                                                    labelKey="forms.inputFile.massivePayments.tooltip.error.label"
                                                    fullWidth
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {(uploadType !== FILE_TYPE || fileLoaded) && mode === "edit" && (
                                    <Box>
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.ModalSelectCustom}
                                            key="inputManually"
                                            name="inputManually"
                                            idField="inputManually"
                                            optionCurrencyList={optionCurrencyList}
                                            value={values?.inputManually ? values?.inputManually : manualPaymentList}
                                            currency={optionCurrencyList[0]?.currency}
                                            addItemManual={() => {
                                                dispatch(
                                                    formActions.rolePaymentSetDetailData(
                                                        values.inputManually,
                                                        values.amount,
                                                        true,
                                                        true,
                                                        values.debitAccount,
                                                        values.commentary,
                                                        values.scheduler,
                                                    ),
                                                );
                                                if (isSuppliersPayment) {
                                                    dispatch(
                                                        routerActions.push({
                                                            pathname: "/suppliersPayment/request/processDetail",
                                                            state: { showModal: !values.inputManually?.length },
                                                        }),
                                                    );
                                                } else {
                                                    dispatch(
                                                        routerActions.push({
                                                            pathname: "/salaryPayment/request/processDetail",
                                                            state: { showModal: !values.inputManually?.length },
                                                        }),
                                                    );
                                                }
                                            }}
                                            uploadType={uploadType}
                                            optionBankList={optionBankList}
                                            genericProps={genericProps}
                                            infoErrorFile={infoErrorFile}
                                            onChangeAmount={(amountTotal, currency) => {
                                                updateAmountField(amountTotal, currency, setFieldValue);
                                            }}
                                            data={{
                                                validLines,
                                                invalidLines,
                                            }}
                                            values={values}
                                            fileTotalAmount={totalAmount || values?.amount || {}}
                                            noLabel
                                        />
                                    </Box>
                                )}

                                {/* {mode === "edit" && fileLoaded && (
                                    <Box display="flex" className="min-height-10 mt-9" fullWidth flex1>
                                        <Disclaimer
                                            className="mx-n-9 flex"
                                            styled="info"
                                            labelKey="forms.inputFile.massivePayments.tooltip.label"
                                            borderRadius="bottom-default"
                                            fullWidth
                                        />
                                    </Box>
                                )} */}
                            </Box>
                        </Col>
                    </Row>
                )}

                {(mode === "preview" || mode === "view") && (
                    <>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={getDataAmount(values?.amount)}
                                key="amount"
                                name="amount"
                                idField="amount"
                                value={values?.amount}
                                bigDataAmount
                            />
                        </Box>
                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            {(transaction?.data?.backendReference || values?.backendReference) && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction?.data?.backendReference || values?.backendReference}
                                    label={
                                        transaction?.idTransactionStatus === "PROCESSING" ||
                                        transaction?.idTransactionStatus === "REJECTED"
                                            ? "forms.transfers.local.voucherReference.label"
                                            : "forms.transfers.local.voucher.label"
                                    }
                                    shouldRender={
                                        mode === "view" &&
                                        (transaction?.data?.backendReference || values?.backendReference)
                                    }
                                />
                            )}

                            {transaction.data?.pmtId && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction.data?.pmtId}
                                    label="forms.role.payment.reference.label"
                                />
                            )}

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={moment(creationDateTime).format("DD/MM/YYYY")}
                                label="ticketNoTransactional.date.label"
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={getOperationTypeText()}
                                label={`forms.${ID_FORM}.operationType.label`}
                                shouldRender={mode === "view"}
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.debitAccountData?.otherLabel}
                                label={`forms.${ID_FORM}.debitAccount.label`}
                            />
                            {values?.commentary && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={values?.commentary}
                                    label={`forms.${ID_FORM}.commentary.label`}
                                />
                            )}
                            {(!values?.scheduler || values?.scheduler?.selectedOption === schedulerUtils.TODAY) && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={i18nUtils.get(`forms.role.payment.scheduler.immediate.radiobutton.label`)}
                                    label={`forms.${ID_FORM}.scheduler.label`}
                                />
                            )}

                            {values?.scheduler?.selectedOption &&
                            values?.scheduler?.selectedOption !== schedulerUtils.TODAY ? (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    type={SCHEDULER_DATE}
                                    value={values?.scheduler}
                                    label={`forms.${ID_FORM}.scheduler.label`}
                                />
                            ) : null}
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={
                                    values?.uploadBy === "file"
                                        ? i18nUtils.get(`forms.${ID_FORM}.uploadBy.file.label`)
                                        : i18nUtils.get(`forms.${ID_FORM}.uploadBy.manual.label`)
                                }
                                label={`forms.${ID_FORM}.uploadBy.ticket.label`}
                            />

                            {transaction.data?.successPaymentsCount && transaction.data?.successPaymentsCount !== 0 ? (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction.data?.successPaymentsCount}
                                    label="forms.role.payment.payments.success.quantity.label"
                                />
                            ) : null}
                            {transaction.data?.errorPaymentsCount && transaction.data?.errorPaymentsCount !== 0 ? (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction.data?.errorPaymentsCount}
                                    label="forms.role.payment.payments.error.quantity.label"
                                />
                            ) : null}

                            <Box
                                display="flex"
                                position="relative"
                                {...(mode === "view" && { className: "pr-96" })}
                                {...(mode === "preview" ? { column: true } : { alignX: "between" })}
                                alignY="center"
                                gap="3"
                                fullWidth>
                                {mode === "preview" ? (
                                    <Box className="pt-4" fullWidth>
                                        <ShowInfoFile
                                            {...genericProps}
                                            uploadType={uploadType}
                                            mode={mode}
                                            dispatch={dispatch}
                                            currencyCustom={values?.amount?.currency || ""}
                                            amount={values?.amount.quantity || 0}
                                            lines={totalLines || values?.inputManually?.length || 0}
                                        />
                                    </Box>
                                ) : (
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        value={totalLines}
                                        label="forms.role.payment.payments.quantity.label"
                                    />
                                )}
                                <Box {...(mode === "view" && { position: "absolute", right: "0", bottom: "0" })}>
                                    <Button
                                        className="toolbar-btn view-close"
                                        onClick={() => {
                                            const transactionId = match?.params?.idTransaction;
                                            if (transactionId) {
                                                if (ID_FORM.includes("suppliers")) {
                                                    dispatch(
                                                        routerActions.push(
                                                            `/suppliersPayment/${transactionId}/processDetail`,
                                                        ),
                                                    );
                                                } else {
                                                    dispatch(
                                                        routerActions.push(
                                                            `/salaryPayment/${transactionId}/processDetail`,
                                                        ),
                                                    );
                                                }
                                            } else {
                                                dispatch(
                                                    formActions.rolePaymentSetDetailData(
                                                        values.inputManually,
                                                        values.amount,
                                                        values.uploadBy === MANUAL_TYPE,
                                                        false,
                                                        values.debitAccount,
                                                        values.commentary,
                                                        values.scheduler,
                                                    ),
                                                );
                                                if (isSuppliersPayment) {
                                                    dispatch(
                                                        routerActions.push("/suppliersPayment/request/processDetail"),
                                                    );
                                                } else {
                                                    dispatch(
                                                        routerActions.push("/salaryPayment/request/processDetail"),
                                                    );
                                                }
                                            }
                                        }}
                                        label="checkbooks.button.action.detail"
                                        bsStyle="link"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </>
        );
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
    } = props.preData || {};

    const formProps = {
        title,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: true,
            loadTemplatesOnPreview: true,
            templatesShowDisabled: uploadType === "file",
            beforeTemplateLoad,
            schedulable: true,
            programable: false,
            allowRecurrent: false,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        showFilterChips: false,
        useDefaultSubmit: true,
        showSchedulerMessage: false,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema: () => validationSchema,
        submitDisabled: invalidLines > 0,
        hideDownloadButton: true,
        hideSocialNetworkButtons: true,
    };

    const { debitAccountList } = preDataForm || {};
    return (
        <>
            {/* TODO (LOGICA cuando es vacio se muestra el mensaje) */}
            {debitAccountList && debitAccountList?.length === 0 && !match?.params?.idTransaction ? (
                <>
                    <Head onBack={handleBack} title="forms.role.payment.formName" />
                    <MainContainer>
                        <EmptyTextBox text="product.empty.text.accounts.planilla" className="px-5 py-5" />
                    </MainContainer>
                </>
            ) : (
                <FormTransition {...props} {...formProps} />
            )}
        </>
    );
};

RolePayment.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    radioButtonOption: string,
    postData: shape({}),
    totalLines: number,
    match: shape({}).isRequired,
    fileData: shape({}),
    manualPaymentList: shape([]),
    fromManualRolePayment: bool.isRequired,
    isSuppliersPayment: bool,
    data: shape({}),
};
RolePayment.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "edit",
    currentLang: "",
    preDataForm: null,
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    radioButtonOption: "",
    postData: {},
    totalLines: 0,
    fileData: null,
    manualPaymentList: [],
    isSuppliersPayment: false,
    data: {},
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
    fileData: formSelectors.getRolePaymentFileData(state),
    manualPaymentList: formSelectors.getRolePaymentList(state),
    fromManualRolePayment: formSelectors.isFromManualRolePayment(state),
});
export default compose(connect(mapStateToProps), withRouter)(RolePayment);
