import * as form from "middleware/form";
import * as updateUserDataApi from "middleware/updateUserData/updateUserData.middleware";
import { replace } from "react-router-redux";
import { actions as notificationActions } from "reducers/notification";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";
import * as i18n from "util/i18n";

import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
    types as updateUserDataTypes,
} from "reducers/updateUserData/updateUserData.reducer";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { adjustIdFieldErrors } from "util/form.js";

function* getCredencialsGroups({ credential }) {
    const { activityId } = credential;
    const response = yield call(form.listCredentialsGroups, "", activityId);
    if (!response?.type || !response?.data?.data) {
        yield put(updateUserDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
        yield;
        return;
    }

    if (response.type === "W") {
        yield put(updateUserDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
        yield;
        return;
    }

    const { groups } = response?.data?.data;
    if (!groups) {
        yield put(updateUserDataTypes.GET_CREDENTIAL_GROUPS_FAILURE);
        yield;
        return;
    }

    yield put({
        type: updateUserDataTypes.GET_CREDENTIAL_GROUPS_SUCCESS,
        credentialsGroups: groups,
    });
}

function* updateUserDataPreRequest({ otp, formikBag, location }) {
    try {
        const response = yield call(updateUserDataApi.updateUserDataPreRequest, otp);
        if (!response?.type || !response?.data?.data) {
            yield put(updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE);
            yield;
        } else if (response.type === "W") {
            if (response.data.data.otp === "returnCode.COR091W") {
                yield put(updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE);
            } else if (response?.data?.code === "returnCode.API901W") {
                yield put(updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE);
                yield put({ type: updateUserDataTypes.HIDE_UPDATE_USER_DATA_STEP_ZERO });
                yield put(
                    notificationActions.showNotification(i18n.get("client.remediation.blocked"), "error", ["settings"]),
                );
            } else {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                formikBag.setSubmitting(false);
            }
        } else {
            const { currentUserData, _exchangeToken } = response?.data?.data;
            if (!currentUserData || !_exchangeToken) {
                yield put(updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE);
                yield;
            } else {
                yield put({
                    type: updateUserDataTypes.UPDATE_USER_DATA_PRE_SUCCESS,
                    data: { currentUserData, exchangeToken: _exchangeToken },
                });
                yield put(replace("/settings/personalDataUpdateStep1"));
            }
        }
    } catch (error) {
        const { data } = error;
        if (data?.code === "COR005E") {
            if (location?.pathname !== "/desktop") {
                yield put(replace("/desktop"));
            }
            yield put(updateUserDataActions.hideUpdateUserDataStepZero());
            yield put(notificationActions.showNotification(i18n.get("returnCode.COR005E"), "error", ["desktop"]));
        } else {
            const codeError = ["API1001E", "API1000E", "API1002E"];
            if (codeError.includes(data?.code)) {
                throw error;
            }
            const message = data?.message || i18n.get("global.unexpectedError");
            const errorObj = { otp: message };
            formikBag.setErrors(adjustIdFieldErrors(errorObj));
            formikBag.setSubmitting(false);
        }
    }
}

function* preFormStep1({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep1PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const { documentData, idData, civilStatusList, educationLevelList, professionList } = response.data?.data;
        const preDataStep1 = {
            documentData,
            idData,
            civilStatusList,
            educationLevelList,
            professionList,
        };
        yield put(updateUserDataActions.preFormStep1Success(preDataStep1));
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
    }
}

function* preFormStep2({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep2PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const { countryList, provinceList, districtList, jurisdictionList, cityList } = response.data.data;
        const preDataStep2 = {
            countryList,
            provinceList,
            districtList,
            jurisdictionList,
            cityList,
        };
        yield put(updateUserDataActions.preFormStep2Success(preDataStep2));
    } else if (response && response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* preFormStep3({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep3PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const {
            industryTypeList,
            businessTypeList,
            countryList,
            provinceList,
            districtList,
            jurisdictionList,
            cityList,
            incomeTypeList,
            otherIncomeSourceList,
        } = response.data?.data;
        const preDataStep3 = {
            industryTypeList,
            businessTypeList,
            countryList,
            provinceList,
            districtList,
            jurisdictionList,
            cityList,
            incomeTypeList,
            otherIncomeSourceList,
        };
        yield put(updateUserDataActions.preFormStep3Success(preDataStep3));
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* preFormStep4({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep4PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        // const {  } = response.data?.data;
        const preDataStep4 = {};
        yield put(updateUserDataActions.preFormStep4Success(preDataStep4));
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* preFormStep5({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep5PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const { countryName, countryList } = response.data?.data;
        const preDataStep5 = { countryName, countryList };
        yield put(updateUserDataActions.preFormStep5Success(preDataStep5));
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* preFormStep6({ exchangeToken }) {
    const response = yield call(updateUserDataApi.updateUserDataStep6PreData, exchangeToken);

    if (response && response.data && response.data.code === "COR000I" && response.data.data) {
        const {
            skipStep6,
            mandatoryDocuments,
            additionalDocuments,
            identificationDocuments,
            residenceDocsDisclaimer,
            employmentDocsDisclaimer,
            identityDocument,
        } = response.data?.data;
        const preDataStep6 = {
            skipStep6,
            mandatoryDocuments,
            additionalDocuments,
            residenceDocsDisclaimer,
            employmentDocsDisclaimer,
            identificationDocuments,
        };

        yield put(updateUserDataActions.preFormStep6Success(preDataStep6, identityDocument));

        if (skipStep6) {
            yield put(updateUserDataActions.skippingStep6());
            yield put(
                updateUserDataActions.saveForm(
                    {
                        skipStep6: true,
                    },
                    6,
                    {
                        props: {
                            exchangeToken,
                        },
                    },
                ),
            );
        }
    } else if (response.data?.code === "COR020W") {
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings"));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.requestFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

const readFileAsDataUrl = (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };
        fileReader.readAsDataURL(file);
    });
};

function* selectFile({ file, isMandatory, internalIndex, labelKey, identityDocument, isIdentification }) {
    const result = yield readFileAsDataUrl(file);
    if (isMandatory) {
        const selectedMandatoryAtts = yield select(updateUserDataSelectors.getSelectedMandatoryAttachments);
        const index = selectedMandatoryAtts.map((e) => e.idx).indexOf(internalIndex);

        const newElement = {
            idx: internalIndex,
            name: file.name,
            size: file.size,
            content: result.split(",")[1],
        };

        if (selectedMandatoryAtts.length === 0 || index < 0) {
            selectedMandatoryAtts.push(newElement);
        } else if (index >= 0) {
            selectedMandatoryAtts[index] = newElement;
        }
        yield put(updateUserDataActions.updateMandatoryAttachments(selectedMandatoryAtts));
    } else if (isIdentification) {
        const newElement = {
            idx: internalIndex,
            name: file.name,
            size: file.size,
            content: result.split(",")[1],
        };

        if (
            labelKey === "settings.personalDataUpdateStep6.idPassport.label" ||
            labelKey === "settings.personalDataUpdateStep6.idCard.label"
        ) {
            const exchangeToken = yield select(updateUserDataSelectors.getExchangeToken);
            const typeDocument = labelKey === "settings.personalDataUpdateStep6.idPassport.label" ? "PSP" : null;
            yield put(updateUserDataActions.validateDocumentRequest());
            const response = yield call(
                updateUserDataApi.validateDocument,
                newElement,
                identityDocument,
                typeDocument,
                exchangeToken,
            );
            if (response && response.status === 200) {
                const validationResult = response.data?.data?.documentValidationResult;
                const frontSideMissing = response.data?.data?.frontSideMissing;
                if (!validationResult && !frontSideMissing) {
                    yield put(updateUserDataActions.validateDocumentFailure());
                    yield put(
                        notificationActions.showNotification(
                            `${i18n.get("settings.personalDataUpdateStep6.attachDocuments.invalidDocument.message")}`,
                            "error",
                            ["personalDataUpdate"],
                        ),
                    );
                    return;
                }
                const documentValidationOcr = response.data?.data?.documentValidationOcr;
                yield put(updateUserDataActions.validateDocumentSuccess(validationResult, documentValidationOcr));
            } else {
                yield put(updateUserDataActions.validateDocumentFailure());
                yield put(
                    notificationActions.showNotification(
                        `${i18n.get("settings.personalDataUpdateStep6.attachDocuments.invalidDocument.error")}`,
                        "error",
                        ["personalDataUpdate"],
                    ),
                );
                return;
            }
        }
        let selectedIdentificationAtts = yield select(updateUserDataSelectors.getSelectedIdentificationAttachments);
        const index = selectedIdentificationAtts.map((e) => e.idx).find((idx) => idx === internalIndex);
        if (selectedIdentificationAtts.length === 0 || index === undefined || index < 0) {
            selectedIdentificationAtts.push(newElement);
        } else if (index >= 0) {
            // eslint-disable-next-line no-confusing-arrow
            selectedIdentificationAtts = selectedIdentificationAtts.map((item) =>
                item.idx === index
                    ? {
                          idx: internalIndex,
                          name: file.name,
                          size: file.size,
                          content: result.split(",")[1],
                      }
                    : item,
            );
        }
        yield put(updateUserDataActions.updateIdentificationAttachments(selectedIdentificationAtts));
    } else {
        let selectedAdditionalAtts = yield select(updateUserDataSelectors.getSelectedAdditionalAttachments);
        const index = selectedAdditionalAtts.map((e) => e.idx).find((idx) => idx === internalIndex);
        if (selectedAdditionalAtts.length === 0 || index === undefined || index < 0) {
            selectedAdditionalAtts.push({
                idx: internalIndex,
                name: file.name,
                size: file.size,
                content: result.split(",")[1],
            });
        } else if (index >= 0) {
            // eslint-disable-next-line no-confusing-arrow
            selectedAdditionalAtts = selectedAdditionalAtts.map((item) =>
                item.idx === index
                    ? {
                          idx: internalIndex,
                          name: file.name,
                          size: file.size,
                          content: result.split(",")[1],
                      }
                    : item,
            );
        }
        yield put(updateUserDataActions.updateAdditionalAttachments(selectedAdditionalAtts));
    }
}

function* saveForm({ values, step, formikBag }) {
    const activityMap = new Map([
        [1, { id: "settings.userData.update.step1.send", callBack: updateUserDataApi.saveUserDataStep }],
        [2, { id: "settings.userData.update.step2.send", callBack: updateUserDataApi.saveUserDataStep }],
        [3, { id: "settings.userData.update.step3.send", callBack: updateUserDataApi.saveUserDataStep }],
        [4, { id: "settings.userData.update.step4.send", callBack: updateUserDataApi.saveUserDataStep }],
        [5, { id: "settings.userData.update.step5.send", callBack: updateUserDataApi.saveUserDataStep }],
        [6, { id: "settings.userData.update.step6.send", callBack: updateUserDataApi.saveUserDataStep6 }],
    ]);

    const nextStepMap = new Map([
        [1, "/settings/personalDataUpdateStep2"],
        [2, "/settings/personalDataUpdateStep3"],
        [3, "/settings/personalDataUpdateStep4"],
        [4, "/settings/personalDataUpdateStep5"],
        [5, "/settings/personalDataUpdateStep6"],
        [6, "/settings/personalDataUpdateStep7"],
    ]);

    const activityId = activityMap.get(step);
    if (!activityId) {
        return;
    }

    const { id: actId, callBack } = activityId;
    const { exchangeToken } = formikBag?.props;
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "settings.userData.update.step6.send");

    try {
        const response = yield call(callBack, actId, values, exchangeToken, fingerPrintToken);

        if (response.type === "W") {
            yield put(replace("/settings/personalDataUpdateError"));
            return;
        }

        if (response && response.status === 200) {
            yield put(updateUserDataActions.saveFormSuccess());
            let nextStep = nextStepMap.get(step);
            const { data } = response.data;
            if (actId === "settings.userData.update.step6.send" && data?.navigateStep8) {
                nextStep = "/settings/personalDataUpdateStep8";
            }
            if (actId === "settings.userData.update.step2.send") {
                yield put(updateUserDataActions.setCountryCodeUserData(values?.country));
            }
            yield put(replace(nextStep));
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
            yield put(updateUserDataActions.saveFormFailure());
        }
    } catch (error) {
        yield put(replace("/settings/personalDataUpdateError"));
    }
}

function* getDistrictList({ provinceCode }) {
    const response = yield call(updateUserDataApi.getCatalogListByParentCatalogName, "DISTRITO", provinceCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const districtByProvinceList = recordList || {};
        yield put(updateUserDataActions.getDistrictListSuccess(districtByProvinceList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.getDistrictListFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* getJurisdictionList({ districtCode }) {
    const response = yield call(updateUserDataApi.getCatalogListByParentCatalogName, "CORREGIMIENTO", districtCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const jurisdictionList = recordList || {};
        yield put(updateUserDataActions.getJurisdictionListSuccess(jurisdictionList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.getJurisdictionListFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* getProvinceList({ countryCode }) {
    const response = yield call(updateUserDataApi.getCatalogListByParentCatalogName, "PROVINCIA", countryCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        const provinceList = recordList || {};
        yield put(updateUserDataActions.getProvinceListSuccess(provinceList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.getProvinceListFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* getCityList({ stateProvinceCode }) {
    const response = yield call(updateUserDataApi.getCatalogListByParentCatalogName, "CIUDAD", stateProvinceCode);

    if (response && response.status === 200 && response.data?.code === "COR000I") {
        const { recordList } = response.data?.data;
        yield put(updateUserDataActions.getCityListSuccess(recordList));
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["personalDataUpdate"]));
        yield put(updateUserDataActions.getCityListFailure());
        yield put(replace("/settings/personalDataUpdateStep1"));
    }
}

function* goToSettings() {
    yield put(replace("/settings"));
}

function* validateClientRemediation() {
    yield put({ type: updateUserDataTypes.SHOW_UPDATE_USER_DATA_STEP_ZERO });
    const response = yield call(updateUserDataApi.validateClientRemediation);

    if (!response?.type || !response?.data?.data) {
        yield put({ type: updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE });
        yield;
        return;
    }

    if (response?.data?.code === "API901W") {
        yield put(notificationActions.showNotification(i18n.get("client.remediation.blocked"), "error", ["settings"]));
        yield;
        return;
    }

    if (response.type === "W") {
        yield put({ type: updateUserDataTypes.UPDATE_USER_DATA_PRE_FAILURE });
        yield;
        return;
    }
    yield put(
        updateUserDataActions.getUpdateInformation(
            response?.data?.data.hasUpdatesProcessing,
            response?.data?.data.isNeedingUpdate,
            response?.data?.data.lastUpdDt,
        ),
    );
}

const sagas = [
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_PRE_REQUEST, updateUserDataPreRequest),
    takeLatest(updateUserDataTypes.GET_CREDENTIAL_GROUPS_REQUEST, getCredencialsGroups),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP1_PRE_REQUEST, preFormStep1),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP2_PRE_REQUEST, preFormStep2),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP3_PRE_REQUEST, preFormStep3),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP4_PRE_REQUEST, preFormStep4),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP5_PRE_REQUEST, preFormStep5),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_STEP6_PRE_REQUEST, preFormStep6),
    takeLatest(updateUserDataTypes.UPDATE_USER_DATA_SAVE_FORM, saveForm),
    takeLatest(updateUserDataTypes.GET_DISTRICT_LIST_REQUEST, getDistrictList),
    takeLatest(updateUserDataTypes.GET_JURISDICTION_LIST_REQUEST, getJurisdictionList),
    takeLatest(updateUserDataTypes.GET_PROVINCE_LIST_REQUEST, getProvinceList),
    takeLatest(updateUserDataTypes.GET_CITY_LIST_REQUEST, getCityList),
    takeLatest(updateUserDataTypes.SELECT_FILE, selectFile),
    takeLatest(updateUserDataTypes.GO_TO_SETTINGS, goToSettings),
    takeLatest(updateUserDataTypes.VALIDATE_CLIENT_REMEDIATION, validateClientRemediation),
];

export default sagas;
