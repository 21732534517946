import classNames from "classnames";
import SupportTransferReceivedModal from "pages/SupportTransferReceived/SupportTransferReceivedModal";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Loader from "pages/_components/Loader";
import { Field, Formik } from "formik";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { replace } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import {
    actions as transferSupportActions,
    selectors as transferSupportSelectors,
} from "reducers/supportTransferReceived";

const ID_FORM = "support.transfer.foreign.received";
const ID_ACTIVITY = `${ID_FORM}.send`;

const ListSupportTransferReceived = ({
    isDesktop,
    dispatch,
    fetching,
    accounts,
    fetchingAccounts,
    transferList,
    fetchingSend,
}) => {
    const [supportTransferSelected, setSupportTransferSelected] = useState(null);
    const [accountId, setAccountId] = useState("");
    /**
     * Load data
     */
    useEffect(() => {
        dispatch(accountsActions.listAccounts());
    }, []);

    const generateAccountComponent = (debitAccountList) => ({
        options: debitAccountList
            ? debitAccountList.map((acc) => ({
                  ...acc,
                  balance: { currency: acc.currency, quantity: acc.balance },
                  id: acc.idProduct,
                  disabled: acc.isDisabled || acc.simplified,
              }))
            : [],
    });

    const accountOptions = generateAccountComponent(accounts);

    /**
     * Modal support actions
     */
    const [showModal, setShowModal] = useState(false);

    const handleBack = () => {
        dispatch(replace("/desktop"));
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleModal = (supportTranfer) => {
        setSupportTransferSelected(supportTranfer);
        setShowModal(true);
    };

    /**Save data */
    const handleSubmit = (values) => {
        dispatch(
            transferSupportActions.sendUploadSupportRequest(
                accountId,
                supportTransferSelected?.idStatement,
                values.supportFiles,
                values.reason,
                values.relation[0]?.id || "",
            ),
        );
        setShowModal(false);
    };

    const getColumnsTemplate = () => (isDesktop ? "1fr 1fr 1fr 1.5fr 1.5fr 3rem" : "1fr 1fr 2rem");

    const renderContentTable = () => (
        <GridTable.Body>
            {fetching && (
                <Loader
                    zIndex="8"
                    borderRadius="md"
                    classicStyle="true"
                    bgTransparent={false}
                    loaderSmallBoxed={false}
                />
            )}
            {transferList?.length > 0 &&
                transferList.map((data, index) => (
                    <GridTable.Container
                        id={"ListSupport-" + index}
                        className={classNames({ "gapX-3": isDesktop })}
                        padding={isDesktop ? "py-2 px-5" : "py-2 pl-5 pr-3"}
                        {...(!isDesktop && { row: 2 })}
                        columnsTemplate={getColumnsTemplate()}>
                        <GridTable.Data columnStart={1} alignX="flex-start">
                            {isDesktop ? (
                                <FormattedDate date={data.date} anotherFormat="DD/MM/YYYY" />
                            ) : (
                                <Box display="flex" column withEllipsis>
                                    <Text color="secondary" size="5" bold ellipsis>
                                        {data.recipient}
                                    </Text>
                                    <FormattedDate date={data.date} anotherFormat="DD/MM/YYYY" size="5" />
                                </Box>
                            )}
                        </GridTable.Data>
                        <GridTable.Data columnStart={2} alignX={isDesktop ? "flex-start" : "flex-end"}>
                            {isDesktop ? (
                                <Text ellipsis>{data.concept}</Text>
                            ) : (
                                <Box display="flex" alignY="flex-end" column withEllipsis>
                                    <Text size="5" ellipsis>
                                        {data.idStatement}
                                    </Text>
                                    <FormattedAmount
                                        quantity={data.credit}
                                        currency={data.currency}
                                        className="credit-card-value"
                                        size="5"
                                    />
                                </Box>
                            )}
                        </GridTable.Data>

                        {isDesktop && (
                            <>
                                <GridTable.Data columnStart={3} alignX="center">
                                    <Text ellipsis align="center">
                                        {data.idStatement}
                                    </Text>
                                </GridTable.Data>
                                <GridTable.Data columnStart={4} alignX="flex-end">
                                    <FormattedAmount
                                        quantity={data.credit}
                                        currency={data.currency}
                                        className="credit-card-value"
                                        size="6"
                                    />
                                </GridTable.Data>
                            </>
                        )}
                        <GridTable.Data
                            columnStart={isDesktop ? 5 : 1}
                            {...(!isDesktop && { rowStart: 2, columnWidth: 2 })}
                            alignX={isDesktop ? "center" : "flex-end"}>
                            {isDesktop ? (
                                <>
                                    <Button
                                        bsStyle="link"
                                        {...(!isDesktop && { className: "px-0" })}
                                        label={`forms.${ID_FORM}.sustenance.button`}
                                        onClick={() => handleModal(data)}
                                    />
                                </>
                            ) : (
                                <Button
                                    bsStyle="chip"
                                    {...(!isDesktop && { className: "px-0" })}
                                    label={`forms.${ID_FORM}.sustenance.button`}
                                    onClick={() => handleModal(data)}
                                />
                            )}
                        </GridTable.Data>
                    </GridTable.Container>
                ))}
            {transferList.length === 0 && (
                <Box
                    display="flex"
                    fullWidth
                    alignX="center"
                    className={classNames("no-more-data ", {
                        "min-height-8rem": transferList.length === 0,
                    })}>
                    <Text
                        labelKey="transfers.foreign.support.list.empty"
                        component="p"
                        light
                        align="center"
                        size={isDesktop ? "6" : "5"}
                        color="text"
                    />
                </Box>
            )}
        </GridTable.Body>
    );

    return (
        <>
            <Notification scopeToShow="ListSupportTransferReceived" />
            <Head onBack={handleBack} title={`forms.${ID_FORM}.title`} />
            <MainContainer showLoader={fetchingAccounts}>
                <Box
                    component="nav"
                    background="white"
                    {...(isDesktop && { borderRadius: "default" })}
                    className="p-5 p-lg-7 mb-2 mb-md-7 mx-n-5 mt-3 mt-md-0">
                    <Box display="flex" fullWidth alignX="center">
                        <Text
                            labelKey={`forms.${ID_FORM}.subtitle`}
                            component="h3"
                            align="center"
                            size="20"
                            color="heading-color"
                            bold
                        />
                    </Box>
                </Box>

                <Box
                    borderRadius="default"
                    background="component-background"
                    className="mx-n-5 pt-7 pt-md-8 mt-3 mt-md-0 mb-3 mb-md-0">
                    <Row className="mb-7 mb-md-8">
                        <Col xs={12} md={6}>
                            <Box
                                display="flex"
                                alignX="flex-start"
                                gap={isDesktop ? "7" : "5"}
                                {...(!isDesktop && { column: true })}
                                className="px-5">
                                <Formik>
                                    <Field
                                        component={FormFieldsComponents.ProductselectorCustom}
                                        data={accountOptions}
                                        key="account"
                                        name="account"
                                        idField="account"
                                        renderAs="combo"
                                        value={""}
                                        mode="edit"
                                        idActivity={ID_ACTIVITY}
                                        isRequired
                                        onChangeDataCustom={(accountSel) => {
                                            setAccountId(accountSel);
                                            dispatch(transferSupportActions.getTransferListRequest(accountSel));
                                        }}
                                    />
                                </Formik>
                            </Box>
                        </Col>
                    </Row>
                    <Box className="min-height-10rem">
                        <GridTable>
                            <GridTable.Header>
                                <GridTable.Container
                                    className={classNames({ "gapX-3": isDesktop })}
                                    padding={isDesktop ? "py-2 px-5" : "py-2 pl-5 pr-3"}
                                    columnsTemplate={getColumnsTemplate()}>
                                    <GridTable.Data columnStart={1} alignX="flex-start" inHeader>
                                        <Text
                                            labelKey={
                                                isDesktop
                                                    ? `forms.${ID_FORM}.date.label`
                                                    : `forms.${ID_FORM}.date2.label`
                                            }
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data
                                        columnStart={2}
                                        alignX={isDesktop ? "flex-start" : "flex-end"}
                                        inHeader>
                                        <Text
                                            labelKey={
                                                isDesktop
                                                    ? `forms.${ID_FORM}.description.label`
                                                    : `forms.${ID_FORM}.description2.label`
                                            }
                                        />
                                    </GridTable.Data>
                                    {isDesktop && (
                                        <>
                                            <GridTable.Data columnStart={3} alignX="flex-end" inHeader>
                                                <Text labelKey={`forms.${ID_FORM}.tracking.label`} />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={4} alignX="center" inHeader>
                                                <Text labelKey={`forms.${ID_FORM}.amount.label`} />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={5} alignX="center" inHeader>
                                                <Text labelKey={`forms.${ID_FORM}.sustenance.label`} />
                                            </GridTable.Data>
                                        </>
                                    )}
                                </GridTable.Container>
                            </GridTable.Header>
                            {renderContentTable()}
                        </GridTable>
                    </Box>
                </Box>
            </MainContainer>

            <SupportTransferReceivedModal
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                scopeError={["ListSupportTransferReceived"]}
                onSubmit={handleSubmit}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    transferList: transferSupportSelectors.getTransferList(state),
    fetching: transferSupportSelectors.isFetching(state),
    fetchingSend: transferSupportSelectors.isFetchingSend(state),
    accounts: accountsSelectors.getAccounts(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
});

ListSupportTransferReceived.propTypes = {
    isDesktop: bool.isRequired,
    history: shape({}).isRequired,
    dispatch: func.isRequired,
    totalPages: number.isRequired,
    transferList: arrayOf(shape({})),
    fetching: bool,
    accounts: arrayOf(shape({ length: number })),
    fetchingAccounts: bool,
    fetchingSend: bool,
};

ListSupportTransferReceived.defaultProps = {
    transferList: [],
    fetching: false,
    fetchingAccounts: false,
    fetchingSend: false,
    accounts: [],
};

export default connect(mapStateToProps)(withRouter(ListSupportTransferReceived));
